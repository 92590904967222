import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: { main: "#d31548" },
        secondary: { main: "#ea6721" },
        error: { main: "#ea6721" },
        background: {
            default: "#000"
        }
    }
    // themeName
});
// const themeName = "Crimson Tango Oryx";

export default theme;

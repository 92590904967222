import 'typeface-montserrat';
import 'typeface-lato';
import 'typeface-roboto';
import 'typeface-roboto-slab';

import './src/styles/vendors/reset.styl';
import './src/styles/vendors/slick.css';
import './src/styles/vendors/slick-theme.css';
import './src/styles/layout.styl';
import './src/styles/global.styl';

// export const onServiceWorkerUpdateReady = () => {
//     // window.location.reload(true);
//     window.location.reload();
// };

export const onClientEntry = () => {
    // Without this function body the import will not be picked up.
};

// import ReduxWrapper from "./src/providers/ReduxWrapper";

// export const wrapRootElement = ReduxWrapper;

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-views-templates-product-template-js": () => import("../src/views/templates/ProductTemplate.js" /* webpackChunkName: "component---src-views-templates-product-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gift-card-js": () => import("../src/pages/gift-card.js" /* webpackChunkName: "component---src-pages-gift-card-js" */),
  "component---src-pages-gift-item-js": () => import("../src/pages/gift-item.js" /* webpackChunkName: "component---src-pages-gift-item-js" */),
  "component---src-pages-gift-wrap-js": () => import("../src/pages/gift-wrap.js" /* webpackChunkName: "component---src-pages-gift-wrap-js" */),
  "component---src-pages-group-js": () => import("../src/pages/group.js" /* webpackChunkName: "component---src-pages-group-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notices-js": () => import("../src/pages/legal-notices.js" /* webpackChunkName: "component---src-pages-legal-notices-js" */)
}

